import React from 'react';
import ReactMarkdown from 'react-markdown';
import classNames from 'classnames';
import ReactPlayer from 'react-player';
import { Element, scroller } from 'react-scroll';
import style from './project_page.module.scss';
import { Layouts, Button } from '../components/components';
import { Promo, ProjectsModules, SliderBlock, PictureWithText, SmallItemsList, QuestionList, CircularList } from '../modules/modules';
import { PictureWithTextType, PromoBlockType, SliderBlocksType, FontWeight, SmallItemType } from '../lib/constants/enums';
import { SitePageContextProject } from '../graphql-types';
import { replaceStrapiComponentName } from '../lib/utils';
import { StrapiBlocksName } from '../lib/constants/strapi';
import { colors } from '../styles/variables';
import ColorService from '../lib/services/colorService';
import remarkGfm from 'remark-gfm';

interface PageContext {
  project: SitePageContextProject;
  slug: string;
  lang: string;
}

export interface IndexProps {
  pageContext: PageContext;
}

const ProjectPage: React.FC<IndexProps> = ({ pageContext }) => {
  const { project } = pageContext;
  const { IndexLayout, AdaptiveLayout } = Layouts;
  const {
    BulletList,
    ProjectAuthor,
    NumberedList,
    PaymentModule,
    ProjectItemsList,
    ProjectSelect,
    ProjectSmallList,
    ProjectPictureWithText,
    BlockWithCircleImage,
  } = ProjectsModules;
  const colorService = new ColorService();

  return (
    <IndexLayout>
      {project.blocks &&
        project.blocks.length !== 0 &&
        project.blocks.map((block, index) => {
          switch (replaceStrapiComponentName(block.__component)) {
            case StrapiBlocksName.projectSplash:
              return <Promo data={block} generalTheme={project.theme} type={PromoBlockType.project} key={index} />;
            case StrapiBlocksName.bulletList:
              return <BulletList data={block} generalTheme={project.theme} key={index} />;
            case StrapiBlocksName.projectAuthor:
              return <ProjectAuthor data={block} key={index} />;
            case StrapiBlocksName.numberedList:
              return <NumberedList data={block} generalTheme={project.theme} key={index} />;
            case StrapiBlocksName.reviewSlider:
              // console.log(block);
              return <SliderBlock data={block} type={SliderBlocksType.reviews} key={index} />;
            case StrapiBlocksName.paymentBlock:
              return <PaymentModule data={block} generalTheme={project.theme} key={index} />;
            case StrapiBlocksName.pictureWithText:
              return <PictureWithText data={block} type={PictureWithTextType.default} key={index} />;
            case StrapiBlocksName.beforeAfterSlider:
              return <SliderBlock data={block} type={SliderBlocksType.beforeAfter} key={index} />;
            case StrapiBlocksName.smallItemsList:
              return <SmallItemsList data={block} generalTheme={project.theme} type={SmallItemType.column} key={index} backgroundCircle />;
            case StrapiBlocksName.projectItemsList:
              return <ProjectItemsList data={block} key={index} />;
            case StrapiBlocksName.projectSelect:
              return <ProjectSelect data={block} key={index} />;
            case StrapiBlocksName.projectSmallList:
              return <ProjectSmallList data={block} key={index} />;
            case StrapiBlocksName.questionList:
              return <QuestionList data={block} generalTheme={project.theme} key={index} />;
            case StrapiBlocksName.projectPictureWithText:
              return <ProjectPictureWithText data={block} generalTheme={project.theme} key={index} />;
            case StrapiBlocksName.circularList:
              return <CircularList data={block} generalTheme={project.theme} key={index} />;
            case StrapiBlocksName.projectBlockWithCircleImage:
              return <BlockWithCircleImage data={block} generalTheme={project.theme} key={index} />;
            case StrapiBlocksName.buttonBlock:
              return (
                <div className={style.buttonBlock} key={index}>
                  <h2 className={style.buttonBlockTitle}>{block?.title}</h2>
                  <div className={style.buttonBlockInfo}>
                    <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={block?.description || ''} remarkPlugins={[remarkGfm]} />
                  </div>
                  {block?.CTA && block.CTA.buttonTitle && (
                    <a className={style.btn} href={block.CTA.btnLink || '#'}>
                      <AdaptiveLayout onDesktop onTablet>
                        <Button title={block.CTA.buttonTitle} border uppercase fontSize={23} fontWeight={FontWeight.bold} />
                      </AdaptiveLayout>
                      <AdaptiveLayout onMobile>
                        <Button title={block.CTA.buttonTitle} border uppercase fontSize={18} fontWeight={FontWeight.bold} />
                      </AdaptiveLayout>
                    </a>
                  )}
                </div>
              );
            case StrapiBlocksName.projectResult:
              return (
                <div className={style.resultsWrapper} key={index}>
                  <h2
                    style={{ color: (project.theme && project.theme?.mainColor && project.theme.mainColor) || colors.black }}
                    className={style.resultsTitle}
                  >
                    {block?.title}
                  </h2>
                  <div className={style.resultsList}>
                    {block?.projectResultItems &&
                      block.projectResultItems.length !== 0 &&
                      block.projectResultItems.map((el, index) => (
                        <div className={style.resultItem} key={el?.id || index}>
                          <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={el?.description || ''} remarkPlugins={[remarkGfm]} />
                        </div>
                      ))}
                  </div>
                </div>
              );
            case StrapiBlocksName.projectCartList:
              return (
                <div
                  style={{ background: (block?.theme && block?.theme?.backgroundColor) || 'none' }}
                  className={style.cartListWrapper}
                  key={index}
                >
                  <div className={style.cartList}>
                    {block?.ProjectCart &&
                      block.ProjectCart.length !== 0 &&
                      block.ProjectCart.map((el, index) => (
                        <div
                          className={style.cartItem}
                          style={{
                            border: (project?.theme && project.theme?.mainColor && `1px solid ${project.theme.mainColor}`) || 'none',
                          }}
                          key={el?.id || index}
                        >
                          <h4 style={{ color: (project?.theme && project.theme?.mainColor) || colors.black }} className={style.cartTitle}>
                            {el?.title}
                          </h4>
                          <div className={style.cartDescription}>
                            <ReactMarkdown
                              remarkPlugins={[remarkGfm]}
                              transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`}
                              children={el?.description || ''}
                            />
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              );
            case StrapiBlocksName.projectBeforeAfter:
              return (
                <div className={classNames(style.projectBeforeAfter, style.projectContainer)} key={index}>
                  <h2
                    style={{ color: (project.theme && project.theme.mainColor) || colors.black }}
                    className={style.projectBeforeAfterTitle}
                  >
                    {block?.title}
                  </h2>
                  <div className={style.projectBulletList}>
                    {block?.bulletItem &&
                      block.bulletItem.map((bullet, index) => (
                        <div className={style.bulletItem} key={bullet?.id || index}>
                          <div className={style.bulletIcon}>
                            <img src={`${process.env.STRAPI_API_URL}${block.theme?.bulletImage?.url}`} alt="" />
                          </div>
                          {bullet?.label}
                        </div>
                      ))}
                  </div>
                  <div className={style.beforeAfterList}>
                    {block?.beforeAfterBlock &&
                      block.beforeAfterBlock.map((el, index) => (
                        <div className={style.beforeAfterItem} key={el?.id || index}>
                          <div className={style.beforeAfterHead}>
                            <span>До</span>
                            <span>После</span>
                          </div>
                          <img src={`${process.env.STRAPI_API_URL}${el?.picture?.url}`} alt="" />
                          <div className={style.beforeAfterDescription}>
                            <ReactMarkdown
                              remarkPlugins={[remarkGfm]}
                              transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`}
                              children={el?.description || ''}
                            />
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              );
            case StrapiBlocksName.projectContraindication:
              return (
                <div className={style.projectContraindication} key={index}>
                  <div className={style.projectContainer}>
                    <h2 className={style.projectContraindicationTitle}>{block?.title}</h2>
                    {/* <div className={style.content}>
                      <div className={style.descriptionItem}>
                        <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} source={block?.description || ''} />
                      </div>
                      <div className={style.descriptionItem}>
                        <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} source={block?.additionalDescription || ''} />
                      </div>
                      <div className={style.positionImage}>
                        <img src={`${process.env.STRAPI_API_URL}${block?.picture?.url}`} alt="" />
                      </div>
                      <div className={style.linePosition}>
                        <div style={{ background: (project.theme && project.theme.mainColor) || 'none' }} className={style.line} />
                        <div style={{ background: (project.theme && project.theme.mainColor) || 'none' }} className={style.inverseLine} />
                      </div>
                    </div> */}
                    <div className={style.projectContraindicationExtraInfoBlock}>
                      <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={block?.extraInfo || ''} remarkPlugins={[remarkGfm]}/>
                    </div>
                    {block?.CTA && block.CTA.buttonTitle && (
                      <a className={style.projectContraindicationBtn} href={block.CTA.btnLink || '#'} target="_blank">
                        <AdaptiveLayout onDesktop onTablet>
                          <Button title={block.CTA.buttonTitle} uppercase border={false} fontWeight={FontWeight.bold} fontSize={18} />
                        </AdaptiveLayout>
                        <AdaptiveLayout onMobile>
                          <Button title={block.CTA.buttonTitle} uppercase border={false} fontWeight={FontWeight.bold} fontSize={16} />
                        </AdaptiveLayout>
                      </a>
                    )}
                  </div>
                </div>
              );
            case StrapiBlocksName.projectFlatAuthor:
              return (
                <div className={classNames(style.projectFlatAuthor, style.projectContainer)} key={index}>
                  <h2
                    style={{ color: (project.theme && project.theme.mainColor) || colors.black }}
                    className={style.projectFlatAuthorTitle}
                  >
                    {block?.title}
                  </h2>
                  <img src={`${process.env.STRAPI_API_URL}${block?.picture?.url}`} alt="" />
                  <div className={style.info}>
                    <h2 style={{ color: (project.theme && project.theme.mainColor) || colors.black }} className={style.infotitle}>
                      {block?.title}
                    </h2>
                    <div className={style.infoDescription}>
                      <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={block?.description || ''} remarkPlugins={[remarkGfm]}/>
                    </div>
                  </div>
                </div>
              );
            case StrapiBlocksName.projectThreeElements:
              return (
                <div className={style.projectThreeElements} key={index}>
                  <div className={style.projectContainer}>
                    <h2 className={style.projectThreeElementsTitle}>{block?.title}</h2>
                    <h3
                      className={style.projectThreeElementsSecondTitle}
                      style={{ color: (project.theme && project.theme.mainColor) || colors.black }}
                    >
                      {block?.description}
                    </h3>
                    <div className={style.bulletList}>
                      {block?.bulletItems &&
                        block.bulletItems.map((el, index) => (
                          <div className={classNames(style.bulletItem, { [style.bulletItemLong]: index === 0 })} key={el?.id || index}>
                            {index !== 0 && block.theme && <img src={`${process.env.STRAPI_API_URL}${block.theme?.bulletImage?.url}`} />}
                            <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={el?.label || ''} remarkPlugins={[remarkGfm]}/>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              );
            case StrapiBlocksName.projectCourators:
              return (
                <div className={classNames(style.projectCourators, style.projectContainer)} key={index}>
                  <h2 className={style.projectCouratorsTitle}>{block?.title}</h2>
                  <div className={style.couratorsList}>
                    {block?.projectCouratorsList &&
                      block.projectCouratorsList.map((courator, index) => (
                        <div className={style.couratorItem} key={courator?.id || index}>
                          <img src={`${process.env.STRAPI_API_URL}${courator?.picture?.url}`} alt="" />
                          <h4 className={style.couratorTitle}>{courator?.title}</h4>
                          <div className={style.couratorDescription}>
                            <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={courator?.description || ''} remarkPlugins={[remarkGfm]} />
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              );
            case StrapiBlocksName.projectHowToStart:
              return (
                <Element name="paymentModule" key={index}>
                  <div className={style.projectHowToStart}>
                    <div className={style.projectContainer}>
                      <h2 className={style.howToStartTitle}>{block?.title}</h2>
                      <div className={style.howToStartTopBlock}>
                        <div className={style.howToStartTopBlockItem}>
                          {block?.numberedList &&
                            block.numberedList.map((el, index) => (
                              <div className={style.numberItem} key={el?.id || index}>
                                <div style={{ color: (project.theme && project.theme.mainColor) || colors.black }} className={style.number}>
                                  {index + 1}
                                </div>
                                <div className={style.numberItemInfo}>
                                  <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={el?.label || ''} remarkPlugins={[remarkGfm]} />
                                </div>
                              </div>
                            ))}
                        </div>
                        <div className={style.howToStartTopBlockItem}>
                          <img className={style.howToStartPicture} src={`${process.env.STRAPI_API_URL}${block?.picture?.url}`} alt="" />
                          <div className={style.bulletList}>
                            {block?.bulletList &&
                              block.bulletList.map((el, index) => (
                                <div className={style.bulletItem} key={el?.id || index}>
                                  <img src={`${process.env.STRAPI_API_URL}${block.theme?.bulletImage?.url}`} className={style.bulletIcon} />
                                  <span>{el?.label}</span>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                      <div className={style.howToStartBottomBlock}>
                        {block?.textItems &&
                          block.textItems.map((el, index) => (
                            <div className={style.textItem} key={el?.id || index}>
                              <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={el?.description || ''} remarkPlugins={[remarkGfm]} />
                            </div>
                          ))}
                      </div>
                      {block?.CTA && block.CTA.buttonTitle && (
                        <a className={style.howToStartLink} href={block.CTA.btnLink || '#'} target="_blank">
                          <AdaptiveLayout onDesktop onTablet>
                            <Button title={block.CTA.buttonTitle} border fontWeight={FontWeight.bold} uppercase fontSize={24} />
                          </AdaptiveLayout>
                          <AdaptiveLayout onMobile>
                            <Button title={block.CTA.buttonTitle} border fontWeight={FontWeight.bold} uppercase fontSize={18} />
                          </AdaptiveLayout>
                        </a>
                      )}
                    </div>
                  </div>
                </Element>
              );
            case StrapiBlocksName.porjectFlatPayment:
              return (
                <div className={style.porjectFlatPayment} key={index}>
                  <div className={style.projectContainer}>
                    <div className={style.topBlock}>
                      <h3 style={{ color: (project.theme && project.theme.mainColor) || colors.black }} className={style.blockTitle}>
                        {block?.topBlock?.title}
                      </h3>
                      <div className={style.blockDescription}>
                        <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={block?.topBlock?.description || ''} remarkPlugins={[remarkGfm]} />
                      </div>
                    </div>
                    <div className={style.description}>
                      <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={block?.description || ''} remarkPlugins={[remarkGfm]} />
                    </div>
                    <div className={style.bottomBlock}>
                      {block?.bottomBlock?.title && (
                        <h3 style={{ color: (project.theme && project.theme.mainColor) || colors.black }} className={style.blockTitle}>
                          {block?.bottomBlock?.title}
                        </h3>
                      )}
                      <div className={style.blockDescription}>
                        <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={block?.bottomBlock?.description || ''} remarkPlugins={[remarkGfm]} className={style.prize} />
                      </div>
                      <div className={style.btnList}>
                        {block?.bottomBlock && block?.bottomBlock.btnTitle && block?.bottomBlock.additionalBtnTitle && (
                          <>
                            <a className={style.btnLink} href={block.bottomBlock?.link || '#'}>
                              <AdaptiveLayout onDesktop onTablet>
                                <Button
                                  title={block?.bottomBlock?.btnTitle}
                                  border={false}
                                  fontWeight={FontWeight.bold}
                                  uppercase
                                  fontSize={24}
                                  fontColor={colors.white}
                                  bgColor={project.theme?.mainColor || colors.green}
                                />
                              </AdaptiveLayout>
                              <AdaptiveLayout onMobile>
                                <Button
                                  title={block?.bottomBlock?.btnTitle}
                                  border={false}
                                  fontWeight={FontWeight.medium}
                                  uppercase
                                  fontSize={18}
                                  fontColor={colors.white}
                                  bgColor={project.theme?.mainColor || colors.green}
                                />
                              </AdaptiveLayout>
                            </a>
                            <a className={style.btnLink} href={block.bottomBlock?.additionalLink || '#'}>
                              <AdaptiveLayout onDesktop onTablet>
                                <Button
                                  title={block?.bottomBlock?.additionalBtnTitle}
                                  border={false}
                                  fontWeight={FontWeight.bold}
                                  uppercase
                                  fontSize={24}
                                />
                              </AdaptiveLayout>
                              <AdaptiveLayout onMobile>
                                <Button
                                  title={block?.bottomBlock?.additionalBtnTitle}
                                  border={false}
                                  fontWeight={FontWeight.medium}
                                  uppercase
                                  fontSize={18}
                                />
                              </AdaptiveLayout>
                            </a>
                          </>
                        )}
                      </div>
                    </div>
                    <div className={style.questionInfo}>
                      <h4 style={{ color: (project.theme && project.theme.mainColor) || colors.black }}>Остались вопросы?</h4>
                      <h5>Задай свой вопрос в WhatsApp!</h5>
                      {block?.CTA && block.CTA.buttonTitle && (
                        <a className={style.questionLink} href={block.CTA.btnLink || '#'} target="_blank">
                          <Button title={block.CTA.buttonTitle} border fontWeight={FontWeight.bold} uppercase fontSize={16} />
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              );
            case StrapiBlocksName.projectDiet:
              return (
                <div className={style.projectDiet} key={index}>
                  <div className={style.projectContainer}>
                    <div style={{ color: (project.theme && project.theme.mainColor) || colors.black }} className={style.projectDietTitle}>
                      <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={block?.title || ''} remarkPlugins={[remarkGfm]} />
                    </div>
                    <div className={style.dietList}>
                      <h4 className={style.dietItemTitle}>{block?.additionalTitle}</h4>
                      <div className={style.dietListInner}>
                        <div className={style.dietItem}>
                          {block?.leftItems &&
                            block.leftItems.map((el, index) => (
                              <div className={style.dietLeftItem} key={el?.id || index}>
                                <img src={`${process.env.STRAPI_API_URL}${el?.picture?.url}`} alt="" />
                                <div className={style.dietLeftItemDescription}>
                                  <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={el?.description || ''} remarkPlugins={[remarkGfm]} />
                                </div>
                              </div>
                            ))}
                        </div>
                        <div className={style.dietItem}>
                          <h4 className={style.dietItemTitleMobile}>{block?.additionalTitle}</h4>
                          {block?.rightItems &&
                            block.rightItems.map((el, index) => (
                              <div className={style.dietRightItem} key={el?.id || index}>
                                <h5
                                  style={{ color: (project.theme && project.theme.mainColor) || colors.black }}
                                  className={style.dietRightItemTitle}
                                >
                                  {el?.title}
                                  <img src={`${process.env.STRAPI_API_URL}${block.theme?.bulletImage?.url}`} alt="" />
                                </h5>
                                <div className={style.dietRightItemDescription}>
                                  <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={el?.description || ''} remarkPlugins={[remarkGfm]} />
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            case StrapiBlocksName.projectDietVideo:
              return (
                <div className={style.projectDietVideo} key={index}>
                  <div className={style.projectContainer}>
                    <h2 className={style.projectDietVideoTitle}>{block?.title}</h2>
                    <div className={style.mainContent}>
                      <div className={style.mainItem}>
                        {block?.videoLink && (
                          <div className={style.mainContentVideo}>
                            <ReactPlayer width="100%" height="100%" url={block.videoLink} controls />
                          </div>
                        )}
                      </div>
                      <div className={style.mainItem}>
                        {block?.textItems &&
                          block.textItems.map((el, index) => (
                            <div className={style.mainItemContent} key={el?.id || index}>
                              <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={el?.label || ''} remarkPlugins={[remarkGfm]} />
                            </div>
                          ))}
                      </div>
                    </div>
                    <div style={{ color: (project.theme && project.theme.mainColor) || colors.black }} className={style.extraInfo}>
                      <ReactMarkdown transformImageUri={img => `${process.env.STRAPI_API_URL}${img}`} children={block?.extraInfo || ''} remarkPlugins={[remarkGfm]} />
                    </div>
                    {block?.CTA && block.CTA.buttonTitle && (
                      <div
                        className={style.projectDietVideoLink}
                        onClick={() =>
                          scroller.scrollTo('paymentModule', {
                            duration: 1500,
                            delay: 100,
                            smooth: true,
                            offset: 50,
                          })
                        }
                      >
                        {/* <a className={style.projectDietVideoLink} href={block.CTA.btnLink || '#'}> */}
                        <AdaptiveLayout onDesktop onTablet>
                          <Button
                            title={block.CTA.buttonTitle}
                            border
                            fontColor={colors.white}
                            fontSize={22}
                            fontWeight={FontWeight.bold}
                            uppercase
                            bgColor={project.theme?.mainColor || colors.green}
                            from={project.theme?.mainColor || colors.green}
                            to={project.theme?.mainColor || colors.green}
                            shadowColor={colorService.generateShadow(
                              '0px 8.57143px 48.5714px',
                              project.theme?.mainColor || colors.green,
                              0.5
                            )}
                          />
                        </AdaptiveLayout>
                        <AdaptiveLayout onMobile>
                          <Button
                            title={block.CTA.buttonTitle}
                            border
                            fontColor={colors.white}
                            fontSize={18}
                            fontWeight={FontWeight.bold}
                            uppercase
                            bgColor={project.theme?.mainColor || colors.green}
                            from={project.theme?.mainColor || colors.green}
                            to={project.theme?.mainColor || colors.green}
                            shadowColor={colorService.generateShadow(
                              '0px 8.57143px 48.5714px',
                              project.theme?.mainColor || colors.green,
                              0.5
                            )}
                          />
                        </AdaptiveLayout>
                        {/* </a> */}
                      </div>
                    )}
                  </div>
                </div>
              );
            default:
              return null;
          }
        })}
    </IndexLayout>
  );
};

export default ProjectPage;
